<template>
  <div class="container">
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 添加按钮 -->
      <el-button type="primary" size="small" @click="teacherShow = true">添加线下课程期数</el-button>
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="开课城市" prop="classCity"></el-table-column>
        <el-table-column align="center" label="期数名称" prop="issueName"></el-table-column>
        <el-table-column align="center" label="总名额" prop="places"></el-table-column>
        <el-table-column align="center" label="剩余名额" prop="residuePlaces"></el-table-column>
        <el-table-column align="center" label="开始时间">
          <template slot-scope="scope">{{
              $moment(scope.row.startTime).format("YYYY-MM-DD")
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="结束时间">
          <template slot-scope="scope">{{
              $moment(scope.row.endTime).format("YYYY-MM-DD")
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="期数类型">
          <template slot-scope="scope">
            <el-tag :type="
                scope.row.status == 0
                  ? 'success'
                  : scope.row.status == 1
                  ? 'danger'
                  : ''
              " disable-transitions>{{ typeObj[scope.row.status] }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="320">
          <template slot-scope="scope">
            <el-button type="success" @click="changeApplyList(scope.row)" icon="el-icon-s-data" size="mini">报名列表
            </el-button>
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 弹框 -->
    <el-dialog :title="form.id ? '添加线下课程期数' : '编辑线下课程期数'" :visible.sync="teacherShow" width="40%" @close="handleClose">
      <el-form :model="form" ref="form" label-width="120px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="开课城市:" prop="classCity">
          <el-cascader placeholder="请选择城市" :props="{ value: 'label' }" :options="options"
                       v-model="form.classCity"></el-cascader>
        </el-form-item>
        <el-form-item label="期数名称:" prop="issueName">
          <el-input @focus="clearValidate('issueName')" v-model="form.issueName" placeholder="请输入期数名称"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="本期报名名额:" prop="places">
          <el-input v-model="form.places" type="number" @focus="clearValidate('places')" placeholder="请输入总期数"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开始/结束时间:" prop="startTime" label-width="120px" style="flex: 2">
          <el-date-picker @change="changeData" v-model="date" type="datetimerange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="期数类型:" label-width="120px" style="margin-top: 40px">
          <el-radio-group v-model="form.status">
            <el-radio v-for="item in radioList" :key="item.label" :label="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {issueList, addOnsetIssue} from "../../apis/course";
import {provinceAndCityData} from "element-china-area-data"; // 1.省市不带‘全部’的二级联动
import {nper} from "@/utils/rules";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      teacherShow: false,
      rules: nper.rules,
      date: "", // 绑定开始结束时间
      form: {
        courseId: "", //	线下课程id
        classCity: "", // 城市
        issueName: "", //	期数名称
        places: "", // 本期报名名额
        residuePlaces: "", // 本期剩余报名名额
        startTime: "", //	课程开始时间(date-time)
        endTime: "", //	课程结束时间(date-time)
        status: 0, //	状态(0=正常 1=取消 2=已过期)
      },
      radioList: [
        {
          value: "正常",
          label: 0,
        },
        {
          value: "取消",
          label: 1,
        },
        {
          value: "已过期",
          label: 2,
        },
      ],
      typeObj: {
        0: "正常",
        1: "取消",
        2: "已过期",
      },
      options: provinceAndCityData,
    };
  },
  activated() {
    const id = this.$route.query.id;
    this.form.courseId = id;
    this.form.status = 0;
    this.getData(id);
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await issueList({courseId: this.form.courseId});
      this.tableData = res.data;
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },

    handlerEdit(item) {
      this.teacherShow = true;
      this.form = {...item};
      this.form.classCity = item.classCity.split(",");
      this.date = [item.startTime, item.endTime];
    },
    handleClose() {
      this.teacherShow = false;
      this.clearCen();
    },
    changeApplyList(item) {
      this.$router.push({
        path: "/applyList",
        query: {id: item.id, courseId: this.form.courseId},
      });
    },

    submit() {
      this.$refs.form.validate(async (val) => {
        if (!val) return;
        const {places, classCity} = this.form;
        this.form.residuePlaces = places;
        if (classCity instanceof Array) {
          this.form.classCity = classCity.join(",");
        }
        await addOnsetIssue(this.form);
        this.$message({
          message: this.form.id ? "编辑成功" : "添加成功",
          type: "success",
        });
        this.teacherShow = false;
        this.getData();
        this.clearCen();
      });
    },
    changeData(val) {
      if (!val) return;
      this.form.startTime = this.$moment(val[0]).format("YYYY-MM-DD hh:mm:ss");
      this.form.endTime = this.$moment(val[1]).format("YYYY-MM-DD hh:mm:ss");
    },
    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
    disabledDate(time) {
      // 这里返回true 说明 disabled 不可选
      return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
    },
    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.date = "";
      delete this.form.id; // 删除对象里面的id
      this.form.status = 0;
      this.form.courseId = this.$route.query.id;
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  width: 60%;
}
</style>
